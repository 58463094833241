import React from 'react';
import './navbar.css';
import { Link } from 'gatsby';
import Dropdown, { DropdownItem } from "./dropdown";
import { CgMenu, CgClose } from 'react-icons/cg'; //hamburger menu icon and X icon

//company logo
import logo from "../../images/optosweden-rgb.svg";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navbar_active: false,
            mobile_menu: false,
        }

        this.mobile_menu = this.mobile_menu.bind(this);
        this.navbar_active = this.navbar_active.bind(this);
    }

    //open and close mobile menu
    mobile_menu(event) {
        event.preventDefault();
        if (this.state.mobile_menu === false) {
            this.setState({ mobile_menu: true })
        }
        else if (this.state.mobile_menu === true) {
            this.setState({ mobile_menu: false })
        }
    }

    //Change nav background if scrolled
    navbar_active() {
        if (window.scrollY >= 5) {
            this.setState({ navbar_active: true });
        } else {
            this.setState({ navbar_active: false });
        }
    }

    render() {
        //Event listener for scrolling
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', this.navbar_active)
        }

        return (
            <div style={{ height: '80px' }}>
                <nav className="navbar" id={this.state.navbar_active ? 'scroll_active' : 'scroll_disable'}>
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="items_wrapper">
                            <Link to="/">
                                <img className="h-20 py-5 xl:py-4 z-47 hover:opacity-90" src={logo} alt="Optosweden"/>
                            </Link>

                            <div className={this.state.mobile_menu ? 'items active' : 'items'}>
                                <Link to='/' className="mb-6 lg:mb-0 text-black-custome hover:text-blue-custome flex items-center">Hem</Link>
                                <Dropdown text="Tjänster">
                                    <div className="flex flex-col justify-center items-start bg-gray-100 lg:bg-white py-2  lg:shadow-lg rounded-lg">
                                        <DropdownItem href="/dokument" value="Intelligenta automationsplattformar"/>
                                        <DropdownItem href="/service" value="Service &amp; support"/>
                                        <DropdownItem href="/skanningsprogramvara" value="Skanningsprogramvara"/>
                                        <DropdownItem href="/skanners" value="Skanners" border={false}/>
                                    </div>
                                </Dropdown>
                                <Link to='/support' className="mb-6 ml-0 lg:mb-0 lg:ml-6 text-black-custome hover:text-blue-custome">Support</Link>
                                <Link to='/om-oss' className="mb-6 ml-0 lg:mb-0 lg:ml-6 text-black-custome hover:text-blue-custome">Om oss</Link>
                                <Link to='/nyheter' className="mb-6 ml-0 lg:mb-0 lg:ml-6 text-black-custome hover:text-blue-custome">Nyheter</Link>
                                <Link to='/demo' className="px-8 py-2 rounded-full ml-0 lg:ml-6 text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</Link>
                            </div>
                                           
                            <button className='block lg:hidden text-4xl text-black-custome hover:text-blue-custome z-47' onClick={this.mobile_menu} onKeyDown={this.mobile_menu} >
                                {this.state.mobile_menu ? <CgClose /> : <CgMenu />}
                            </button>
                        </div> 
                    </div>
                </nav>
            </div>
        );
    }
}
export default Navbar;

